import gql from 'graphql-tag'
import { singularize, pluralize, camelize } from 'inflection'
import { TypeKind } from 'graphql'
import { print as _print } from 'graphql/language'
import * as gqlTypes from 'graphql-ast-types-browser'

function print(arg) {
  const res = _print(arg)
  // console.log('print', arg, res)
  return res
}
/**
 * Ensure we get the real type even if the root type is NON_NULL or LIST
 * (taken from react-admin)
 */
const getFinalType = type => {
  if (type.kind === TypeKind.NON_NULL || type.kind === TypeKind.LIST) {
    return getFinalType(type.ofType)
  }

  return type
}

const directFieldsFilter = field =>
  field.type.kind === 'SCALAR' ||
  field.type.kind === 'ENUM' ||
  (field.type.kind === 'NON_NULL' && field.type.ofType.kind === 'SCALAR')

const buildFieldList = (introspectionResults, resource, raFetchType) => {
  const directFields = resource.type.fields
    .filter(f => f.name !== 'nodeId')
    .filter(directFieldsFilter)
    .map(field => {
      return gqlTypes.field(gqlTypes.name(field.name))
    })
  const toManyFields = resource.type.fields
    .map(field => {
      const type = getFinalType(field.type)
      const linked = introspectionResults.resources.find(r => r.type.name === type.name)
      if (!linked) {
        return null
      }
      return gqlTypes.field(
        gqlTypes.name(field.name),
        null,
        null,
        null,
        gqlTypes.selectionSet([gqlTypes.field(gqlTypes.name('id'))])
      )
    })
    .filter(f => !!f)
  return gqlTypes.selectionSet([...directFields, ...toManyFields])
}

const paramsToAllQueryOptions = (params, searchField) => {
  const out = {}
  const { pagination, sort, filter } = params

  let sortValue = ''
  let filterValue = ''

  if (filter && filter.q) {
    if (!searchField) {
      throw new Error('No searchField defined, unable to search')
    }
    filterValue = `filter: {${searchField}: {includesInsensitive: "${filter.q}"}}`
  }
  if (filter && filter.verbatim) {
    filterValue = filter.verbatim
  }

  if (sort && sort.order) {
    sortValue = `orderBy: ${sort.field.toUpperCase()}_${sort.order}`
  }
  if (pagination) {
    out.offset = (pagination.page - 1) * pagination.perPage
    out.first = pagination.perPage
  }
  const paginationValue = Object.keys(out).length
    ? `${Object.keys(out)
        .map(k => `${k}: ${out[k]} `)
        .join()}`
    : ''
  const options = [paginationValue, sortValue, filterValue].filter(s => s.length > 0)
  const allValues = options.length ? `(${options.join(', ')})` : ''
  console.log('paramsToAllQueryOptions, allValues', allValues)
  return allValues
}

const myProvider = (introspectionResults, queryMapping) => (
  raFetchType,
  rawResourceName,
  params
) => {
  const resourceName = camelize(singularize(rawResourceName))
  const resource = introspectionResults.resources.find(r => r.type.name === resourceName)
  console.log('Resource', resource)
  if (!resource) {
    throw new Error(`Resource ${rawResourceName} not found`)
  }
  console.log(
    'build-query',
    raFetchType,
    resourceName,
    params,
    `#resources=${introspectionResults.resources.length}`
  )

  const parseManyResponse = response => {
    console.log('parseManyResponse, res', response)
    const res = {
      ...(response.data ? response.data : {}),
      total: response.data ? response.data.data.total : undefined,
      data: response.data ? response.data.data.edges.map(({ node }) => node) : null
    }
    console.log('parseManyResponse', res)
    return res
  }
  switch (raFetchType) {
    case 'GET_ONE':
      return {
        query: gql`query ${resource[raFetchType].name}($id: Int!) {
                    data: ${resource[raFetchType].name}(id: $id)
                        ${print(buildFieldList(introspectionResults, resource, raFetchType))}
                }`,
        variables: { ...params, id: Number(params.id) }, // params = { id: ... }
        parseResponse: response => response.data
      }
    case 'GET_LIST':
      return {
        query: gql`query ${resourceName} {
          data: all${pluralize(resourceName)}${paramsToAllQueryOptions(
          params,
          queryMapping[resourceName]
        )} {
            total: totalCount
            edges {
              node ${print(buildFieldList(introspectionResults, resource, raFetchType))}
            }
          }
        }`,
        variables: params,
        parseResponse: parseManyResponse
      }
    case 'GET_MANY': {
      const query = gql`query many${resourceName}($ids:[Int!]!) {
          data: all${pluralize(resourceName)}(filter: {id: {in: $ids}}) {
            total: totalCount
            edges {
              node ${print(buildFieldList(introspectionResults, resource, raFetchType))}
            }
          }
        }`
      return {
        query,
        variables: params,
        parseResponse: parseManyResponse
      }
    }
    case 'UPDATE': {
      console.log('UPDATE', resourceName)
      const patchTypeName = `${camelize(resourceName)}Patch`
      const patchType = introspectionResults.types.find(t => t.name === patchTypeName)
      const query = gql`mutation update${resourceName}(
          $patch: ${patchTypeName}!,
          $id:Int!
        ) {
          update${resourceName}ById(input:{
            ${camelize(resourceName, true)}Patch: $patch
            id: $id
          }) {
            updated: ${camelize(resourceName, true)} { id }
          }
        }`

      const { id, data } = params
      const remainingParams = patchType.inputFields
        .filter(f => f.name !== 'id')
        .reduce((accum, f) => {
          if (data[f.name] !== undefined) {
            accum[f.name] = data[f.name]
          }
          return accum
        }, {})

      return {
        query,
        variables: {
          id: Number(id),
          patch: remainingParams
        },
        parseResponse: res => {
          console.log('UPDATE, response', res)
          return {
            data: res.data[`update${resourceName}ById`].updated
          }
        }
      }
    }
    case 'CREATE': {
      console.log('CREATE', resourceName)
      const createTypeName = `${camelize(resourceName)}Input`
      const createType = introspectionResults.types.find(t => t.name === createTypeName)
      const query = gql`mutation create${resourceName}(
          $data: ${createTypeName}!
        ) {
          create${resourceName}(input:{
            ${camelize(resourceName, true)}: $data
          }) {
            created: ${camelize(resourceName, true)} { id }
          }
        }`

      const { data } = params
      console.log('params', params)
      const inputParams = createType.inputFields.reduce((accum, f) => {
        if (data[f.name] !== undefined) {
          accum[f.name] = data[f.name]
        }
        return accum
      }, {})

      return {
        query,
        variables: {
          data: inputParams
        },
        parseResponse: res => {
          console.log('create, res', res)
          return {
            data: res.data['create' + resourceName].created
          }
        }
      }
    }
    case 'DELETE': {
      console.log('DELETE', resourceName)
      const query = gql`mutation delete${resourceName}(
          $id:Int!
        ) {
          delete${resourceName}ById(input:{
            id: $id
          }) {
            deleted: ${camelize(resourceName, true)} { id }
          }
        }`
      const { id } = params
      return {
        query,
        variables: {
          id: Number(id)
        },
        parseResponse: res => {
          return {
            data: res.data[`delete${resourceName}ById`].deleted
          }
        }
      }
    }
    case 'DELETE_MANY': {
      console.log('DELETE_MANY ====>', resourceName)

      const { ids } = params
      const result = ids
        .map(id => {
          return `d${id}: delete${resourceName}ById(input:{id: ${id}}){
            deleted${resourceName}Id
          }`
        })
        .join('\n')

      return {
        query: gql(`mutation deleteMany { \n` + result + `}`),
        variables: {},
        parseResponse: res => {
          return {
            data: []
          }
        }
      }
    }
    case 'GET_MANY_REFERENCE': {
      const { id, target } = params
      return {
        query: gql`
          query ${resourceName}By${camelize(target)}($id: Int!) {
            data: all${pluralize(resourceName)}${paramsToAllQueryOptions(
          {
            ...params,
            filter: { verbatim: `filter: { ${target}: { equalTo: $id } }` }
          },
          queryMapping[resourceName]
        )} {
              total: totalCount
              edges {
                node ${print(buildFieldList(introspectionResults, resource, raFetchType))}
              }
            }
          }
        `,
        variables: {
          id
        },
        parseResponse: parseManyResponse
      }
    }
    // ... other types handled here
    default:
      // TODO: do nothing?
      throw new Error(`fetch type not yet supported: ${raFetchType}`)
  }
}

export default queryMapping => introspectionResults => (raFetchType, resourceName, params) => {
  try {
    const _myProvider = myProvider(introspectionResults, queryMapping)
    return _myProvider(raFetchType, resourceName, params)
  } catch (e) {
    console.log('ERR', e)
    throw e
  }
}
