import React from 'react'
import { List, Datagrid, TextField, DateField, ReferenceField, BooleanField } from 'react-admin'

export const RespondentsList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField label="User" source="userId" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField label="Assignment" source="assignmentId" reference="assignments">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="role" />
      <TextField source="status" />
      <DateField source="createdAt" showTime />
      <BooleanField source="isInvitationSent" />
    </Datagrid>
  </List>
)
