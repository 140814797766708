import React from 'react'
import {
  List,
  Filter,
  Datagrid,
  SimpleShowLayout,
  Show,
  RefreshButton,
  CardActions,
  TextInput,
  TextField,
  Create,
  SimpleForm,
  FunctionField,
  EditButton
} from 'react-admin'
import { cardActionStyle } from '../card-actions'
import Edit from '../edit'
import Button from '@material-ui/core/Button'
import GetApp from '@material-ui/icons/GetApp'
import saveFile from 'save-as-file'

export const SurveyFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
)
export const SurveyList = props => (
  <List filters={<SurveyFilter />} {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <EditButton />
      <FunctionField render={record => <DownloadResponses scan={record} />} />
    </Datagrid>
  </List>
)

export const SurveyEdit = props => {
  const redirect = `/Surveys`
  return (
    <Edit {...props} redirect={redirect}>
      <SimpleForm>
        <TextInput source="title" />
      </SimpleForm>
    </Edit>
  )
}

export const SurveyCreate = props => {
  const redirect = `/Surveys`
  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <TextInput source="title" />
      </SimpleForm>
    </Create>
  )
}
const SurveyShowActions = ({ basePath }) => (
  <CardActions style={cardActionStyle}>
    <RefreshButton />
  </CardActions>
)

export const SurveyShow = props => (
  <Show title="Survey view" {...props} actions={<SurveyShowActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="__typename" />
    </SimpleShowLayout>
  </Show>
)

export const DownloadResponses = ({ scan }) => {
  const fetchData = async () => {
    console.log('downloading responses')
    const token = JSON.parse(localStorage.getItem('mergon-token'))

    const url = process.env.REACT_APP_PROVIDER
      ? `${process.env.REACT_APP_PROVIDER}/csv`
      : 'http://localhost:3000/csv'

    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Bearer ${token.token}`)

    var raw = JSON.stringify({ scanId: scan.id })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    }

    fetch(url, requestOptions)
      .then(response => response.blob())
      .then(result => saveFile(result, 'survey_responses.csv'))
      .catch(error => alert(error))
  }

  return (
    <Button color="primary" startIcon={<GetApp />} onClick={fetchData}>
      Export
    </Button>
  )
}
