import React from "react";
import {
  List,
  Filter,
  Datagrid,
  TextInput,
  TextField,
  Create,
  SimpleForm
} from "react-admin";
import Edit from "../edit";

const PeerFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const PeerList = props => (
  <List filters={<PeerFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
    </Datagrid>
  </List>
);

export const PeerEdit = props => {
  return (
    <Edit {...props}>
      <SimpleForm redirect="/Peers">
        <TextInput source="name" />
      </SimpleForm>
    </Edit>
  );
};

export const PeerCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm redirect="/Peers">
        <TextInput source="name" />
      </SimpleForm>
    </Create>
  );
};
