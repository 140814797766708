import React from "react";
import {
  List,
  Filter,
  Datagrid,
  SimpleShowLayout,
  Show,
  RefreshButton,
  CardActions,
  TextInput,
  TextField,
  Create,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  EmailField
} from "react-admin";
import { parse } from "query-string";
import Edit from "../edit";
import { cardActionStyle } from "../card-actions";

export const UserFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);
export const UserList = props => (
  <List filters={<UserFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <EmailField source="email" />
      <TextField source="name" />
      <TextField source="surname" />
    </Datagrid>
  </List>
);
export const UserEdit = props => {
  const redirect = `/Users`;
  return (
    <Edit {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput source="organisationId" reference="organisations">
          <SelectInput usertext="name" />
        </ReferenceInput>
        <TextInput source="email" />
        <TextInput source="name" />
        <TextInput source="surname" />
      </SimpleForm>
    </Edit>
  );
};

export const UserCreate = props => {
  const { organisation_id: organisation_id_string } = parse(
    props.location.search
  );
  const organisation_id = organisation_id_string
    ? parseInt(organisation_id_string, 10)
    : "";
  const redirect = `/Users`;
  return (
    <Create {...props}>
      <SimpleForm
        redirect={redirect}
        initialvalue={{ organisationId: organisation_id }}
      >
        <ReferenceInput source="organisationId" reference="organisations">
          <SelectInput usertext="name" />
        </ReferenceInput>
        <TextInput source="email" />
        <TextInput source="name" />
        <TextInput source="surname" />
      </SimpleForm>
    </Create>
  );
};
const UserShowActions = ({ basePath }) => (
  <CardActions style={cardActionStyle}>
    <RefreshButton />
  </CardActions>
);

export const UserShow = props => (
  <Show title="User view" {...props} actions={<UserShowActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <EmailField source="email" />
      <TextField source="name" />
      <TextField source="surname" />
    </SimpleShowLayout>
  </Show>
);
