import React from "react";
import {
  List,
  Filter,
  Datagrid,
  SimpleShowLayout,
  Show,
  RefreshButton,
  CardActions,
  TextInput,
  TextField,
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  NumberField,
  NumberInput
} from "react-admin";
import Edit from "../edit";
import { parse } from "query-string";
import { cardActionStyle } from "../card-actions";

export const QuestionFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);
export const QuestionList = props => (
  <List filters={<QuestionFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="statement" />
      <NumberField source="position" />
      <TextField source="surveyDimensionId" />
    </Datagrid>
  </List>
);
export const QuestionEdit = props => {
  const redirect = `/Questions`;
  return (
    <Edit {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput source="surveyDimensionId" reference="surveyDimensions">
          <SelectInput questionText="statement" />
        </ReferenceInput>
        <TextInput source="statement" />
        <NumberInput source="position" />
      </SimpleForm>
    </Edit>
  );
};

export const QuestionCreate = props => {
  const { surveyDimension_id: surveyDimension_id_string } = parse(
    props.location.search
  );
  const surveyDimension_id = surveyDimension_id_string
    ? parseInt(surveyDimension_id_string, 10)
    : "";
  const redirect = `/Questions`;
  return (
    <Create {...props}>
      <SimpleForm
        redirect={redirect}
        defaultValue={{ surveyDimensionId: surveyDimension_id }}
      >
        <ReferenceInput source="surveyDimensionId" reference="surveyDimensions">
          <SelectInput optionText="title" />
        </ReferenceInput>
        <TextInput source="statement" />
        <NumberInput source="position" />
      </SimpleForm>
    </Create>
  );
};
const QuestionShowActions = ({ basePath }) => (
  <CardActions style={cardActionStyle}>
    <RefreshButton />
  </CardActions>
);

export const QuestionShow = props => (
  <Show title="Question view" {...props} actions={<QuestionShowActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="statement" />
      <NumberField source="position" />
      <ReferenceField source="surveyDimensionId" reference="surveyDimensions">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="__typename" />
    </SimpleShowLayout>
  </Show>
);
