import React from "react";
import {
  List,
  Filter,
  Datagrid,
  SimpleShowLayout,
  Show,
  RefreshButton,
  CardActions,
  TextInput,
  TextField,
  Create,
  SimpleForm,
  NumberField,
  ChipField,
  ReferenceManyField,
  SingleFieldList,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";
import Edit from "../edit";
import { cardActionStyle } from "../card-actions";

export const OrganisationFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);
export const OrganisationList = props => (
  <List filters={<OrganisationFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceManyField
        label="Organisation leaders"
        reference="users"
        target="organisationId"
      >
        <SingleFieldList linkType="show">
          <ChipField source="email" />
        </SingleFieldList>
      </ReferenceManyField>
      <NumberField source="peerId" />
    </Datagrid>
  </List>
);
export const OrganisationEdit = props => {
  const redirect = `/Organisations`;
  return (
    <Edit {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput label="Peer" source="peerId" reference="peers">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" />
      </SimpleForm>
    </Edit>
  );
};

export const OrganisationCreate = props => {
  const redirect = `/Organisations`;
  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput label="Peer" source="peerId" reference="peers">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" />
      </SimpleForm>
    </Create>
  );
};
const OrganisationShowActions = ({ basePath }) => (
  <CardActions style={cardActionStyle}>
    <RefreshButton />
  </CardActions>
);

export const OrganisationShow = props => (
  <Show
    title="Organisation view"
    {...props}
    actions={<OrganisationShowActions />}
  >
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <NumberField source="peerId" />
      <TextField source="__typename" />
    </SimpleShowLayout>
  </Show>
);
