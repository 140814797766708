import React, { useState, useEffect } from "react";
import { HttpLink } from "apollo-link-http";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { CircularProgress } from "@material-ui/core";
import { pluralize, camelize } from "inflection";
import buildGraphQLProvider from "ra-data-graphql";
import { setContext } from "apollo-link-context";
import { useToken } from "../auth";

import {
  GET_LIST,
  GET_ONE
  // GET_MANY,
  // GET_MANY_REFERENCE,
  // CREATE,
  // UPDATE,
  // DELETE,
} from "ra-core";

import buildQuery from "../lib/build-query";

const queryMapping = {
  Question: "statement",
  User: "name",
  Survey: "title",
  Peer: "name",
  Assignment: "assignment"
};

const uri =
  process.env.REACT_APP_API_ENDPOINT || "http://localhost:3000/graphql";
const cache = new InMemoryCache();
const link = new HttpLink({
  uri
});

export default ({ children }) => {
  const [dataProvider, setDataProvider] = useState(null);
  const { token } = useToken();
  useEffect(() => {
    const authLink = setContext((_, { headers }) => {
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`
        }
      };
    });
    buildGraphQLProvider({
      buildQuery: buildQuery(queryMapping),
      introspection: {
        exclude: type => {
          return false;
        }, // TODO: unnecessary?
        operationNames: {
          [GET_LIST]: type => `all${pluralize(type.name)}`,
          [GET_ONE]: type => `${camelize(type.name, true)}ById`
        }
      },
      client: new ApolloClient({
        cache,
        link: authLink.concat(link)
        // name: 'react-web-client',
        //   version: '1.3',
        //   queryDeduplication: false,
        //   defaultOptions: {
        //     watchQuery: {
        //       fetchPolicy: 'cache-and-network',
        //     },
        //   },
      })
    }).then(provider => {
      setDataProvider(() => (type, resource, params) => {
        return provider(type, resource, params).then(res => {
          console.log("res", res);
          return res;
        });
      });
      // setDataProvider(() => (type, resource, params) => {
      //   return fakeDataProvider(type, resource, params)
      //   .then(res => {
      //     console.log('res', res)
      //     return res
      //   })
      // })  // TODO: for testing
    });
  }, [token]);

  if (!dataProvider) {
    return (
      <div style={{ padding: 50 }}>
        <CircularProgress />
      </div>
    );
  }
  return <>{children({ dataProvider })}</>;
};
