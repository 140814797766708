import parseJwt from "./parseJwt";

const slack = 10 /* seconds */ * 1000;
export const warnThreshold = 2 /* minutes */ * 60 * 1000;
function isExpired(time) {
  return time * 1000 - slack < new Date().getTime();
}
export default function isTokenExpired(token) {
  const { exp } = parseJwt(token);
  return !exp || isExpired(exp);
}
