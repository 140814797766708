import React, { useEffect, useContext } from 'react'
import LoginForm from './LoginForm'
import useStoredToken from './useStoredToken'
import { warnThreshold } from './isTokenExpired'
import parseJwt from './parseJwt'
import Context from './Context'

export { default as LogoutButton } from './LogoutButton'

export const authProvider = {
  login: params => Promise.resolve(),
  logout: params => Promise.resolve(),
  checkAuth: params => Promise.resolve(),
  checkError: error => Promise.resolve(),
  getPermissions: params => Promise.resolve()
}

// const LoginWithNonce = ({ onToken }) => {
//   const { nonce, __removeQueryParam } = useQuery();
//   const [fetching, setFetching] = useState(false);

//   useEffect(() => {
//     if (!nonce || fetching) {
//       return;
//     }
//     setFetching(true); // make sure we don't fetch again
//     fetch(provider + "/login?nonce=" + nonce)
//       .then(res => res.json())
//       .then(res => {
//         console.log("res", res);
//         if (res.error) {
//           throw new Error(res.error);
//         }
//         __removeQueryParam("nonce");
//         onToken(res);
//       })
//       .catch(e => alert(e.message));
//   }, [nonce, fetching, onToken, __removeQueryParam]);

//   return null;
// };

export const AuthContext = ({ children }) => {
  const [token, setToken] = useStoredToken()

  // suggest logging in again, if token about to expire
  useEffect(() => {
    console.log(token)
    if (!token) {
      return
    }
    const { exp } = parseJwt(token)
    const expiresAt = new Date(exp * 1000)
    console.log('expiresAt', expiresAt)
    const warnAt = exp * 1000 - warnThreshold
    const timeout = Math.max(1, warnAt - new Date().getTime())
    console.log('timeout', timeout)
    const timer = setTimeout(() => {
      if (window.confirm('Your login is about to expire, re-login?')) {
        setToken(null)
      }
    }, timeout)
    return () => clearTimeout(timer)
  }, [token, setToken])

  if (token) {
    return (
      <Context.Provider value={{ token, logout: () => setToken(null) }}>
        {children}
      </Context.Provider>
    )
  } else {
    return (
      <>
        <LoginForm
          onToken={_token => {
            console.log('_token', _token)
            setToken(_token)
          }}
        />
      </>
    )
  }
}

export const Authenticated = ({ children }) => children
export const useToken = () => {
  const { token } = useContext(Context)
  return token
}
