import React, { forwardRef } from "react";
import { useLogout } from "react-admin";
import MenuItem from "@material-ui/core/MenuItem";
import ExitIcon from "@material-ui/icons/PowerSettingsNew";
import _useLogout from "./useLogout";

export default forwardRef((props, ref) => {
  const logout = useLogout();
  const _logout = _useLogout();
  const handleClick = () => {
    _logout();
    logout();
  };
  return (
    <MenuItem onClick={handleClick} ref={ref}>
      <ExitIcon /> Logout
    </MenuItem>
  );
});
