import React from "react";
import { Admin, Resource /*, ListGuesser */ } from "react-admin";
import DataProvider from "./components/Provider";
import { UserList, UserCreate, UserEdit, UserShow } from "./components/users";
import {
  OrganisationList,
  OrganisationEdit,
  OrganisationShow,
  OrganisationCreate
} from "./components/organisations";
import {
  SurveyList,
  SurveyCreate,
  SurveyShow,
  SurveyEdit
} from "./components/surveys";
import {
  AssignmentList,
  AssignmentCreate,
  AssignmentEdit,
  AssignmentShow
} from "./components/assignments";
import {
  QuestionList,
  QuestionCreate,
  QuestionEdit,
  QuestionShow
} from "./components/questions";
import {
  SurveyDimensionList,
  SurveyDimensionCreate,
  SurveyDimensionEdit,
  SurveyDimensionShow
} from "./components/survey-dimensions";
import { RespondentsList } from "./components/respondents";
import { PeerList, PeerCreate, PeerEdit } from "./components/peers";
import { AuthContext, Authenticated, LogoutButton, authProvider } from "./auth";
import "./App.css";

function App() {
  return (
    <AuthContext>
      <Authenticated>
        <DataProvider>
          {({ dataProvider }) => (
            <Admin
              dataProvider={dataProvider}
              logoutButton={LogoutButton}
              authProvider={authProvider}
            >
              <Resource
                name="users"
                list={UserList}
                create={UserCreate}
                edit={UserEdit}
                show={UserShow}
              />
              <Resource
                name="peers"
                list={PeerList}
                create={PeerCreate}
                edit={PeerEdit}
              />
              <Resource
                name="organisations"
                list={OrganisationList}
                create={OrganisationCreate}
                edit={OrganisationEdit}
                show={OrganisationShow}
              />
              <Resource
                name="surveys"
                list={SurveyList}
                create={SurveyCreate}
                edit={SurveyEdit}
                show={SurveyShow}
              />
              <Resource
                name="assignments"
                options={{ label: "Scans" }}
                list={AssignmentList}
                create={AssignmentCreate}
                edit={AssignmentEdit}
                show={AssignmentShow}
              />
              <Resource
                name="questions"
                list={QuestionList}
                create={QuestionCreate}
                edit={QuestionEdit}
                show={QuestionShow}
              />
              <Resource
                options={{ label: "Survey Dimensions" }}
                name="surveyDimensions"
                list={SurveyDimensionList}
                create={SurveyDimensionCreate}
                edit={SurveyDimensionEdit}
                show={SurveyDimensionShow}
              />
              <Resource name="respondents" list={RespondentsList} />
            </Admin>
          )}
        </DataProvider>
      </Authenticated>
    </AuthContext>
  );
}

export default App;
