import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress
} from "@material-ui/core";
import { provider } from "./provider";
import useStoredToken from "./useStoredToken";
import { AuthContext } from ".";

// for email validation
const emailRegexp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export default props => {
  const [email, setEmail] = useState("");
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [valid, setValid] = useState(false);
  const a = useContext(AuthContext);
  console.log(props);
  useEffect(() => {
    if (email && email.length > 1 && emailRegexp.test(email)) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [email]);

  if (done) {
    return (
      <Container>
        <Dialog open>
          <DialogTitle id="form-dialog-title">
            Login to Mergon Admin
          </DialogTitle>
          <form noValidate>
            <DialogContent>
              <TextField
                name="code"
                value={pin}
                placeholder="Your code"
                onChange={e => setPin(e.target.value)}
              />

              {loading && <CircularProgress />}
              {done && (
                <div>Please check your email, and to get login code.</div>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                disabled={!pin}
                type="button"
                onClick={() => {
                  setLoading(true);
                  fetch(provider + "/login", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                      email,
                      nonce: pin
                    })
                  })
                    .then(response => {
                      if (response.status > 299) {
                        alert("Bad response status: " + response.status);
                      } else {
                        return response.json();
                      }
                    })
                    .then(response => {
                      props.onToken(response);
                    })
                    .catch(e => alert("Error: " + e.message))
                    .finally(() => setLoading(false));
                }}
              >
                Login
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Container>
    );
  }

  return (
    <Container>
      <Dialog open>
        <DialogTitle id="form-dialog-title">Login to Mergon Admin</DialogTitle>
        <form noValidate>
          <DialogContent>
            {!done && !loading && (
              <TextField
                name="email"
                value={email}
                placeholder="Your email"
                onChange={e => setEmail(e.target.value)}
              />
            )}
            {loading && <CircularProgress />}
            {done && (
              <div>Please check your email, and click the link to login.</div>
            )}
          </DialogContent>
          <DialogActions>
            {!done && (
              <Button
                disabled={done || !valid || loading}
                type="button"
                onClick={() => {
                  setLoading(true);
                  fetch(provider + "/send-email", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                      email,
                      redirectUri: window.location.href
                    })
                  })
                    .then(response => {
                      if (response.status > 299) {
                        alert("Bad response status: " + response.status);
                      } else {
                        setDone(true);
                      }
                    })
                    .catch(e => alert("Error: " + e.message))
                    .finally(() => setLoading(false));
                }}
              >
                Log me in
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </Container>
  );
};
