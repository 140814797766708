import React from "react";
import {
  List,
  Filter,
  Datagrid,
  DateField,
  SimpleShowLayout,
  Show,
  RefreshButton,
  CardActions,
  NumberInput,
  TextInput,
  TextField,
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  DateInput
} from "react-admin";
import Edit from "../edit";
import { parse } from "query-string";
import { cardActionStyle } from "../card-actions";

export const AssignmentFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);
export const AssignmentList = props => (
  <List title="Scans" filters={<AssignmentFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="status" />
      <DateField source="dueDate" />
      <DateField source="submissionDate" />
      <TextField source="organisationId" />
      <TextField source="surveyId" />
    </Datagrid>
  </List>
);
export const AssignmentEdit = props => {
  const redirect = `/Assignments`;
  return (
    <Edit {...props} title="Edit Scan">
      <SimpleForm redirect={redirect}>
        <ReferenceInput source="organisationId" reference="organisations">
          <SelectInput AssignmentText="name" />
        </ReferenceInput>
        <TextInput source="name" />
        <TextInput source="description" multiline />
        <DateInput source="dueDate" />
        <DateInput source="submissionDate" />
        <ReferenceInput source="surveyId" reference="surveys">
          <SelectInput optionText="title" />
        </ReferenceInput>
        <NumberInput source="minRespondents" />
        <NumberInput source="maxRespondents" />
      </SimpleForm>
    </Edit>
  );
};

export const AssignmentCreate = props => {
  const { organisation_id: organisation_id_string } = parse(
    props.location.search
  );
  const organisation_id = organisation_id_string
    ? parseInt(organisation_id_string, 10)
    : "";
  const redirect = `/Assignments`;
  return (
    <Create {...props} title="Create New Scan">
      <SimpleForm
        redirect={redirect}
        defaultValue={{ organisationId: organisation_id }}
      >
        <ReferenceInput source="organisationId" reference="organisations">
          <SelectInput assignmentext="name" />
        </ReferenceInput>
        <ReferenceInput source="surveyId" reference="surveys">
          <SelectInput optionText="title" />
        </ReferenceInput>
        <TextInput source="name" />
        <TextInput source="description" multiline />
        <DateInput source="dueDate" />
        <DateInput source="submissionDate" />
        <NumberInput source="minRespondents" />
        <NumberInput source="maxRespondents" />
      </SimpleForm>
    </Create>
  );
};
const AssignmentShowActions = ({ basePath }) => (
  <CardActions style={cardActionStyle}>
    <RefreshButton />
  </CardActions>
);

export const AssignmentShow = props => (
  <Show title="Scan Details" {...props} actions={<AssignmentShowActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="status" />
      <ReferenceField source="organisationId" reference="organisations">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="surveyId" reference="surveys">
        <TextField source="title" />
      </ReferenceField>
      <DateField source="dueDate" showTime />
      <DateField source="submissionDate" showTime />
    </SimpleShowLayout>
  </Show>
);
